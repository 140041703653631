;(function () {
    var $submit = $(".js-feedback-sidebar-submit");

    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    $submit.click(function (event) {

        var $email = $("input[name='feedback-sidebar-email']");
        var $name = $("input[name='feedback-sidebar-name']");
        var $message = $("textarea[name='feedback-sidebar-question']");
        var $politic = $("input[name='feedback-sidebar-politic']");
        var $politicLabel = $(".js-feedback-sidebar-politic-label");
        var $sendResult = $(".js-feedback-sidebar-send-result");

        $submit.prop("disabled", true);
        $sendResult.removeClass("show");
        $sendResult.text("");

        var data = {
            "feedback-email-phone": $email.val(),
            "feedback-name": $name.val(),
            "feedback-message": $message.val(),
            "feedback-politic": $politic.prop("checked")
        };

        var validate = function (data) {

            var err = false;
            $email.removeClass("error");
            $name.removeClass("error");
            $message.removeClass("error");
            $politicLabel.removeClass("error");

            if (
                (data["feedback-email-phone"].length < 4)
                || !validateEmail(data["feedback-email-phone"])
            ) {
                if ((data["feedback-email-phone"].length < 4)) {
                    $email.addClass("error");
                    err = true;
                }
            }

            if (data["feedback-name"].length < 1) {
                $name.addClass("error");
                err = true;
            }

            if (data["feedback-message"].length < 4) {
                $message.addClass("error");
                err = true;
            }

            if (!data["feedback-politic"]) {
                $politicLabel.addClass("error");
                err = true;
            }

            return !err;
        };

        if (validate(data)) {
            data.action = "sendFeedbackSidebar";

            $.ajax({
                url: "/api/index.php",
                type: "post",
                data: data,
                dataType: "json",
                success: function (responce) {
                    $sendResult.addClass("show");
                    if (responce.status === 200) {
                        $sendResult.addClass("success");
                        $sendResult.text("Сообщение успешно получено.");
                    } else if (responce.status === 502) {
                        $sendResult.addClass("error");
                        $sendResult.text("Не удалось отправить сообщение. Пожалуйста, попробуйте позже.");
                    } else if (responce.status === 500) {
                        $sendResult.addClass("error");
                        $sendResult.text("Не удалось отправить сообщение. Пожалуйста, попробуйте позже.");
                    }
                    $submit.prop("disabled", false);
                },
                error: function (jsXHR, textStatus, throwObj) {
                    $sendResult.addClass("show");
                    $sendResult.addClass("error");
                    $sendResult.text("Не удалось отправить сообщение. Пожалуйста, попробуйте позже.");
                    $submit.prop("disabled", false);
                }
            });
        } else {
            $submit.prop("disabled", false);
        }

        return false;
    });
})();