;(function () {
    $(".js-checkbox-label").click(function (event) {
        var $parent = $(this).parent();
        var $checkbox = $parent.find(".js-checkbox");

        if ($checkbox.prop("checked")) {
            $parent.find(".js-checkbox-check-icon").removeClass("active");
            $checkbox.prop('checked', false);
        } else {
            $parent.find(".js-checkbox-check-icon").addClass("active");
            $checkbox.prop('checked', true);
        }

        return true;
    });
})();