;(function () {
    $(".js-feedback-order-toggle-btn").click(function () {
        $(".js-feedback-order-window-wrapper").addClass("show");
        return false;
    });

    $(".js-feedback-order-close").click(function () {
        $(".js-feedback-order-window-wrapper").removeClass("show");
    });

    $("body").on("click", ".js-feedback-order-window-wrapper", function () {
        $(this).removeClass("show");
    });
})();

;(function () {
    $("body").on("click", ".js-feedback-order-window", function (event) {
        if (typeof event.target.attributes.href !== "undefined") return true;
        return false;
    });
})();

;(function () {
    var $submit = $(".js-feedback-order-submit");
    $submit.click(function (event) {

        var $phone = $("input[name='feedback-order-phone']");
        var $name = $("input[name='feedback-order-name']");
        var $politic = $("input[name='feedback-order-politic']");
        var $politicLabel = $(".js-feedback-order-politic-label");
        var $sendResult = $(".js-feedback-order-send-result");

        $submit.prop("disabled", true);
        $sendResult.removeClass("show");
        $sendResult.text("");

        var data = {
            "feedback-order-phone": $phone.val(),
            "feedback-order-name": $name.val(),
            "feedback-order-politic": $politic.prop("checked")
        };

        var validate = function (data) {

            var err = false;
            $phone.removeClass("error");
            $name.removeClass("error");
            $politicLabel.removeClass("error");

            if (data["feedback-order-phone"].length < 4) {
                $phone.addClass("error");
                err = true;
            }

            if (data["feedback-order-name"].length < 1) {
                $name.addClass("error");
                err = true;
            }

            if (!data["feedback-order-politic"]) {
                $politicLabel.addClass("error");
                err = true;
            }

            if (err) return false;
            return true;
        };

        if (validate(data)) {
            data.action = "sendFeedbackOrder";

            $.ajax({
                url: "/api/index.php",
                type: "post",
                data: data,
                dataType: "json",
                success: function (responce) {
                    $sendResult.addClass("show");
                    if (responce.status == 200) {
                        $sendResult.addClass("success");
                        $sendResult.text("Заявка успешно отправлена.");
                    } else if (responce.status == 502) {
                        $sendResult.addClass("error");
                        $sendResult.text("Не удалось отправить заявку. Пожалуйста, попробуйте позже.");
                    } else if (responce.status == 500) {
                        $sendResult.addClass("error");
                        $sendResult.text("Не удалось отправить заявку. Пожалуйста, попробуйте позже.");
                    }
                    $submit.prop("disabled", false);
                },
                error: function (jsXHR, textStatus, throwObj) {
                    $sendResult.addClass("show");
                    $sendResult.addClass("error");
                    $sendResult.text("Не удалось отправить заявку. Пожалуйста, попробуйте позже.");
                    $submit.prop("disabled", false);
                }
            });
        } else {
            $submit.prop("disabled", false);
        }

        return false;
    });
})();