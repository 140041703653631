
;(function () {
    var $submit = $(".js-feedback-order-detail-submit");
    $submit.click(function (event) {

        var $phoneEmail = $("input[name='feedback-order-detail-phone']");
        var $name = $("input[name='feedback-order-detail-name']");
        var $politic = $("input[name='feedback-order-detail-politic']");
        var $politicLabel = $(".js-feedback-order-detail-politic-label");
        var $sendResult = $(".js-feedback-order-detail-send-result");

        $submit.prop("disabled", true);
        $sendResult.removeClass("show");
        $sendResult.text("");

        var data = {
            "feedback-order-phone": $phoneEmail.val(),
            "feedback-order-name": $name.val(),
            "feedback-order-politic": $politic.prop("checked")
        };

        var validate = function (data) {

            var err = false;
            $phoneEmail.removeClass("error");
            $name.removeClass("error");
            $politicLabel.removeClass("error");

            if (data["feedback-order-phone"].length < 4) {
                $phoneEmail.addClass("error");
                err = true;
            }

            if (data["feedback-order-name"].length < 1) {
                $name.addClass("error");
                err = true;
            }

            if (!data["feedback-order-politic"]) {
                $politicLabel.addClass("error");
                err = true;
            }

            return !err;
        };

        if (validate(data)) {
            data.action = "sendFeedbackOrderDetail";

            $.ajax({
                url: "/api/index.php",
                type: "post",
                data: data,
                dataType: "json",
                success: function (responce) {
                    $sendResult.addClass("show");
                    if (responce.status == 200) {
                        $sendResult.addClass("success");
                        $sendResult.text("Заявка успешно отправлена.");
                    } else if (responce.status == 502) {
                        $sendResult.addClass("error");
                        $sendResult.text("Не удалось отправить заявку. Пожалуйста, попробуйте позже.");
                    } else if (responce.status == 500) {
                        $sendResult.addClass("error");
                        $sendResult.text("Не удалось отправить заявку. Пожалуйста, попробуйте позже.");
                    }
                    $submit.prop("disabled", false);
                },
                error: function (jsXHR, textStatus, throwObj) {
                    $sendResult.addClass("show");
                    $sendResult.addClass("error");
                    $sendResult.text("Не удалось отправить заявку. Пожалуйста, попробуйте позже.");
                    $submit.prop("disabled", false);
                }
            });
        } else {
            $submit.prop("disabled", false);
        }

        return false;
    });
})();